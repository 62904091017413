import React, { useEffect, useState } from "react";
import axios from "axios";
import prettyBytes from "pretty-bytes";

function formatDateTime(isoDateTime) {
    const date = new Date(isoDateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}


export default function MainDownloads() {

    const [downloads, setDownloads] = useState([])

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-hoechst.de/news/all/files',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setDownloads(response.data)
                console.log(downloads)
            })
            .catch((error) => {
                console.log(error);
            },);
    }, [])

    return (
        <div className="flex flex-col md:px-28 gap-12 py-8 px-8">
            <div className="w-full">
                <h1 className="text-center md:text-5xl text-3xl font-bold text-gray-200">Downloads</h1>
            </div>
            {downloads.length >= 1 ? (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {downloads.map((entry, index) =>
                        <div className="bg-gray-700 flex flex-col gap-4 rounded-lg p-4 shadow-md">
                            <div
                                className="text-gray-200 markdown-custom text-center flex flex-col text-lg font-semibold"
                                style={{ overflow: "hidden" }}
                            >
                                <p>{entry.file_name}</p>
                                <div className="text-sm text-gray-400">
                                    <p>Größe: {prettyBytes(entry.file_size, { locale: 'de' })}</p>
                                    <p>Hochgeladen: {formatDateTime(entry.upload_at)}</p>
                                </div>

                            </div>
                            <a href={"https://admin.halle-hoechst.de/uploads/" + entry.file_path} className="bg-blue-500 text-center text-white px-4 py-2 rounded hover:bg-blue-600" download target="_blank">
                                Download
                            </a>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <p>Es sind keine Daten da</p>
                </>
            )}
        </div>)
}
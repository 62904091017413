import React, {useEffect, useState} from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import axios from "axios";

export default function VideoMain() {


    const [video, setVideo] = useState([])

    useEffect(() => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-höchst.de/video/list',
        };

        axios.request(config)
            .then(response => response.data)
            .then(data => {
                setVideo(data);
                console.log(video)
            })
            .catch(error => {
                console.log('error', error);
            });
    }, []);


    return (
        <div className='lg:px-28 pt-12 pb-12 px-12 w-full grid md:grid-cols-3 gap-6 sm:grid-cols-2 grid-cols-1 items-center justify-center'>
            {video.length > 0 ? (
                video.map(videoData => (
                        <div className='shadow-2xl'>
                            <LiteYouTubeEmbed
                                id={videoData.url}
                                title={videoData.title}
                            />
                        </div>
                    ))
            ) : (
                <div>
                </div>
            )}
        </div>
    )
}
import React, {useEffect, useRef} from 'react';
import $ from 'jquery';
import 'datatables.net-responsive';
import 'datatables.net-dt'
import '../../../../node_modules/datatables.net-dt/css/dataTables.dataTables.min.css';
import '../../../lib/responsive.dataTables.css'

export default function MainEcki() {
    const tableRef = useRef(null);

    useEffect(() => {
        if (!tableRef.current) return;

        const dataTable = $(tableRef.current).DataTable({
            columnDefs: [
                { width: "9%", targets: 0 },
                { width: "30%", targets: 1 },
                { width: "25%", targets: 2 },
                { width: "25%", targets: 3 }
            ],
            ajax: {
                url: 'https://admin.halle-hoechst.de/tips/list',
                method: 'GET',
                dataSrc: '',
            },
            responsive: false,
            paging: false,
            scrollCollapse: true,
            scrollY: '600px',
            pageLength: 10,
            autoWidth: false,
            ordering: false,
            scrollX: true,
            language: {
                sEmptyTable: "Keine Daten in der Tabelle vorhanden",
                sInfo: "_START_ bis _END_ von _TOTAL_ Einträgen",
                sInfoEmpty: "0 bis 0 von 0 Einträgen",
                sInfoFiltered: "(gefiltert von _MAX_ Einträgen)",
                sInfoPostFix: "",
                sInfoThousands: ".",
                sLengthMenu: "_MENU_",
                sLoadingRecords: "Wird geladen...",
                sProcessing: "Bitte warten...",
                sSearch: "Suchen: ",
                sZeroRecords: "Keine Einträge vorhanden.",
                oPaginate: {
                    sFirst: "Erste",
                    sPrevious: "Zurück",
                    sNext: "Nächste",
                    sLast: "Letzte"
                },
                oAria: {
                    sSortAscending: ": aktivieren, um Spalte aufsteigend zu sortieren",
                    sSortDescending: ": aktivieren, um Spalte absteigend zu sortieren"
                }
                // Sprachanpassungen hier
            },
            columns: [
                {
                    data: "date",
                    className: "bg-gray-700 text-gray-200 border-gray-700",
                    title: "Datum",
                    render: function(data, type, row) {
                        var date = new Date(data);
                        var days = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
                        var day = days[date.getDay()];
                        var formattedDate = ("0" + date.getDate()).slice(-2) + '.' + ("0" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
                        return day + ', ' + formattedDate;
                    }
                },
                {
                    data: 'title',
                    className: "bg-gray-700 text-gray-200 border-gray-700",
                    title: "Band / Künstler / Festival / Sonstiges",
                    render: function(data, type, row) {
                        // Verbesserter RegExp, der Leerzeichen am Ende nicht einschließt
                        var urlRegex = /(www.[\S]+?.[\S]+?)(?=\s|$)/;
                        var found = data.match(urlRegex);

                        // Überprüfen, ob eine URL gefunden wurde
                        if (found) {
                            // Entfernt Leerzeichen am Anfang und Ende der URL
                            var url = found[0].trim();
                            // Ersetzt die gefundene URL durch einen anklickbaren Link
                            var replace = '<a href="https://' + url + '" target="_blank">' + url + '</a>';
                            return data.replace(found[0], replace);
                        }

                        // Gibt den ursprünglichen Text zurück, wenn keine URL gefunden wurde
                        return data;
                    }

                },
                {
                    data: 'location',
                    className: "bg-gray-700 text-gray-200 border-gray-700",
                    title: "Veranstaltungsort",
                },
                {
                    data: 'website',
                    className: "bg-gray-700 text-gray-200 border-gray-700",
                    title: "Website zur Veranstaltung",
                    render: function(data, type, row) {
                        return `<a href=https://${data} target='_blank'>${data}</a>`;
                    }
                }
            ]
        });


        setTimeout(() => {

            const colgroups = $(tableRef.current).find('colgroup');
            if (colgroups.length > 1) {
                colgroups.not(':first').remove();
            }

            dataTable.columns.adjust().draw();
        }, 0);

        return () => {
            dataTable.destroy();
        };
    }, []);


    return (
        <div className='h-auto md:mx-28 mx-2 pt-12 pb-12 flex flex-col items-center justify-center'>
            <div className='pb-8 text-gray-200 text-[30px]'>
                <h1>Eckis Ausgehtipps</h1>
            </div>
            <div className='bg-gray-700 !text-gray-200 p-4 rounded-lg shadow-xl w-full'>
                <table ref={tableRef} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-700 text-white">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Datum
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Band/Künstler/Sonstiges
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Ort
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Link
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {/* Hier Ihre Daten */}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


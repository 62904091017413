import React, {useEffect} from 'react';
import Navbar from "../navbar";
import MainGallery from "../mainComponents/gallery/mainGallery";
import Footer from "../footer";

export default function Gallery() {

    useEffect(() => {
        document.title = 'Halle Höchst | Galerie'
    }, [])


    return (
        <main className=''>
            <Navbar/>
            <MainGallery/>
            <Footer/>
        </main>
    )

}
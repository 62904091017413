import React, {useEffect} from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import Partner from "../partner";
import MainEcki from "../mainComponents/eckistipps/mainEcki";

export default function Eckistipps() {

    useEffect(() => {
        document.title = 'Halle Höchst | EckisTipps'
    }, [])


    return (
        <main>
            <Navbar/>
            <MainEcki/>
            <Partner/>
            <Footer/>
        </main>
    )
}
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Captions } from "yet-another-react-lightbox/plugins";
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

export default function MainAlbum() {
    const [photos, setPhotos] = useState([]); // State für die Fotos
    const [index, setIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(true); // Zustand für das Laden
    const captionsRef = React.useRef(null);
    const zoomRef = React.useRef(null);
    const [animationDuration, setAnimationDuration] = React.useState(500);
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = React.useState(4);
    const [zoomInMultiplier, setZoomInMultiplier] = React.useState(2);
    const [doubleTapDelay, setDoubleTapDelay] = React.useState(300);
    const [doubleClickDelay, setDoubleClickDelay] = React.useState(300);
    const [doubleClickMaxStops, setDoubleClickMaxStops] = React.useState(2);
    const [keyboardMoveDistance, setKeyboardMoveDistance] = React.useState(50);
    const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] =
        React.useState(100);
    const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] =
        React.useState(100);
    const [scrollToZoom, setScrollToZoom] = React.useState(false);

    let { id } = useParams();

    useEffect(() => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://admin.halle-höchst.de/gallery/pictures/' + id,
        };

        axios.request(config)
            .then(response => response.data)
            .then(data => {
                setPhotos(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log('error', error);
                setIsLoading(false);
            });
    }, [id]);

    return (
        <div className='w-full px-4 py-8'>
            {isLoading ? (
                <div>
                    <p>Lädt..</p>
                </div>
            ) : (
                <div>
                    <PhotoAlbum
                        className='!rounded-3xl shadow-2xl'
                        layout="rows"
                        photos={photos}
                        onClick={({ index: current }) => setIndex(current)}
                    />
                    <Lightbox
                        index={index}
                        plugins={[Captions, Zoom]}
                        slides={photos}
                        inline={{
                            style: { width: "100%", maxWidth: "3900px", aspectRatio: "3 / 1" },
                        }}
                        animation={{ zoom: animationDuration }}
                        zoom={{
                            ref: zoomRef,
                            maxZoomPixelRatio,
                            zoomInMultiplier,
                            doubleTapDelay,
                            doubleClickDelay,
                            doubleClickMaxStops,
                            keyboardMoveDistance,
                            wheelZoomDistanceFactor,
                            pinchZoomDistanceFactor,
                            scrollToZoom,
                        }}
                        captions={{
                            captions: photos.map(photo => photo.caption),
                            ref: captionsRef
                        }}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        on={{
                            click: () => {
                                (captionsRef.current?.visible
                                    ? captionsRef.current?.hide
                                    : captionsRef.current?.show)?.();
                            },
                        }}
                    />
                </div>
            )}
        </div>
    );
}

import React, {useState, useEffect} from 'react';
import Logo from '../assets/logo.webp'
import {RxHamburgerMenu} from "react-icons/rx";
import Button from "./mainComponents/assetComponents/button";
import {Link} from 'react-router-dom'
import {FaTimes} from 'react-icons/fa';
import CordesHosting2 from '../assets/button_poweredby_400x90_2.png'
import {CiFacebook, CiInstagram} from "react-icons/ci";

export default function Navbar() {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Verhindern des Scrollens auf der Hauptseite, wenn das Menü geöffnet ist
            document.body.style.overflow = 'hidden';
        } else {
            // Scrollen wieder ermöglichen, wenn das Menü geschlossen ist
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]); // Abhängigkeit von isOpen

    return (
        <div className='flex flex-col justify-center items-center pt-4 duration-500 ease-in-out md:mx-28 mx-4'>
            {isOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={() => setIsOpen(false)}></div>
            )}
            <div className='pb-4 md:flex hidden'>
                <img src={Logo} alt='Logo' className='rounded-2xl max-h-[125px]'/>
            </div>
            <div className='hidden max-[1259px]:!flex pb-4'>
                <div className='flex flex-col md:text-2xl text-lg gap-3 justify-center pt-4 items-center text-center'>
                    <a href='https://www.facebook.com/DieHalleHoechst' target='_blank' rel='noreferrer'
                       className='flex cursor-pointer text-gray-200 justify-center flex-row items-center gap-2'>
                        <CiFacebook className='facebook rounded-lg'/>
                        <p>Facebook</p>
                    </a>
                    <a href='https://www.instagram.com/halle_hoechst_party/' target='_blank' rel='noreferrer'
                       className='flex cursor-pointer text-gray-200 justify-center flex-row items-center gap-2'>
                        <CiInstagram className='instagram rounded-lg'/>
                        <p>Instagram</p>
                    </a>
                </div>
            </div>
            <div
                className='flex px-8 flex-row justify-around items-center rounded-xl shadow-2xl w-full h-[100px] bg-gray-700 md:mx-16 md:gap-2 gap-4 w-full border border-gray-400'>
                <div className='items-center justify-center min-[802px]:hidden flex'>
                    <img src={Logo} alt='Logo' className='rounded-2xl max-h-[80px]'/>
                </div>
                <div className='min-[802px]:flex hidden'>
                    <ul className='flex w-full flex-row select-none justify-around gap-16 max-[1058px]:gap-6 text-gray-200'>
                        <Link to='/home'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Home</li>
                        </Link>
                        <Link to='/news'>
                        <li className='cursor-pointer hover:text-gray-400 duration-300'>News</li>
                        </Link>
                        <Link to='/gallery'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Galerie</li>
                        </Link>
                        <Link to='/eckistipps'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Eckis Ausgehtipps</li>
                        </Link>
                        <Link to='/aboutUs'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Über uns</li>
                        </Link>
                        <Link to='/videos'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Videos</li>
                        </Link>
                        <Link to='/downloads'>
                            <li className='cursor-pointer hover:text-gray-400 duration-300'>Downloads</li>
                        </Link>
                    </ul>
                </div>
                <div className='min-[802px]:hidden flex'>
                    <button onClick={() => setIsOpen(!isOpen)} className="text-xl">
                        <RxHamburgerMenu className='text-[30px] text-gray-200'/>
                    </button>
                </div>
                <div className='min-[802px]:flex max-[1259px]:!hidden h-full'>
                    <div
                        className='flex h-full w-full flex-row md:text-2xl text-lg gap-3 justify-center items-center text-center'>
                        <a href='https://www.facebook.com/DieHalleHoechst' target='_blank' rel='noreferrer'
                           className='flex cursor-pointer text-gray-200 justify-center flex-row items-center gap-2'>
                            <CiFacebook className='facebook w-[40px] h-[40px] rounded-2xl'/>
                        </a>
                        <a href='https://www.instagram.com/halle_hoechst_party/' target='_blank' rel='noreferrer'
                           className='flex cursor-pointer text-gray-200 justify-center flex-row items-center gap-2'>
                            <CiInstagram className='instagram w-[40px] h-[40px] rounded-2xl'/>
                        </a>
                    </div>
                </div>
                <div
                    className={`${isOpen ? 'translate-x-0' : 'translate-x-full'} min-[802px]:hidden fixed right-0 top-0 max-[276px]:!w-[170px] !w-[250px] h-full bg-white p-5 transition-transform card-background z-40`}>
                    <div className='flex items-center justify-center pb-8'>
                        <img alt='Logo' src={Logo} className='w-40'/>
                    </div>
                    <div>
                        <button onClick={() => setIsOpen(!isOpen)} className="text-xl pb-8">
                            <FaTimes className='text-gray-800'/>
                        </button>
                    </div>
                    <ul className="flex flex-col">
                        <Link to='/'>
                            <li className="py-2 font-bold">Home</li>
                        </Link>
                        <Link to='/news'>
                            <li className="py-2 font-bold cursor-pointer">News</li>
                        </Link>
                        <Link to='/gallery'>
                            <li className="py-2 font-bold cursor-pointer">Galerie</li>
                        </Link>
                        <Link to='/eckistipps'>
                            <li className="py-2 pt-3 font-bold cursor-pointer">Eckis Ausgehtipps</li>
                        </Link>
                        <Link to='/aboutUs'>
                            <li className="py-2 pt-3 font-bold cursor-pointer">Über uns</li>
                        </Link>
                        <Link to='/videos'>
                            <li className="py-2 pt-3 font-bold cursor-pointer">Videos</li>
                        </Link>
                        <Link to='/downloads'>
                            <li className="py-2 pt-3 font-bold cursor-pointer">Downloads</li>
                        </Link>
                        <div className='border border-gray w-[95%] mt-4 mb-4'></div>
                        <a href='https://ref.cordes-hosting.de/hallehoechst' target='_blank' rel="noreferrer">
                            <img src={CordesHosting2} alt='Powerd by Cordes-Hosting' className=''/>
                        </a>

                    </ul>
                </div>
            </div>

        </div>
    )
}
import React from 'react';

export default function MainImpress() {
    return (
        <div className="flex flex-col items-center justify-center max-w-4xl mx-auto px-4 py-8 bg-gray-800 text-gray-200">
            <h2 className="text-2xl md:text-3xl font-bold mb-6">Impressum</h2>
            <div className="w-full md:flex md:justify-between">
                <div className="mb-6 md:mb-0 md:w-1/2">
                    <p className="font-semibold mb-2">Angaben gemäß § 5 TMG</p>
                    <address className="not-italic">
                        <p>vert. d. ua. Eckhard Hummel</p>
                        <p>Im Veilgärtchen 5</p>
                        <p>63571 Gelnhausen OT Höchst</p>
                    </address>
                </div>
                <div className="md:w-1/2">
                    <section className="mb-6">
                        <h3 className="font-semibold mb-2">Kontakt</h3>
                        <p>Telefon: +49 (0) 176 2271 3459</p>
                        <p>E-Mail: <a href="mailto:info@halle-hoechst.de" className="text-blue-500 hover:text-blue-400">info@halle-hoechst.de</a></p>
                    </section>
                    <section>
                        <h3 className="font-semibold mb-2">Redaktionell verantwortlich</h3>
                        <p>Eckhard Hummel</p>
                        <p>Im Veilgärtchen 5</p>
                        <p>63571 Gelnhausen OT Höchst</p>
                    </section>
                </div>
            </div>
            <p className="mt-6">Quelle: eRecht24</p>
        </div>
    );
}

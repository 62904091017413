import React from 'react';
import Logo from '../assets/logo.webp'
import CordesHosting from '../assets/button_poweredby_400x90_3.png'
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <div className='flex justify-center md:px-20 px-0 flex-col items-center'>
            <footer
                className='footer w-full py-8 px-6 flex border-t border-l border-r border-gray-600 shadow-2xl flex-col items-center justify-center text-gray-200 bg-gray-700 md:mx-28'>

                <div className='container mx-auto md:gap-0 gap-8 px-2 md:px-0 flex flex-col md:flex-row sm:gap-5 items-center justify-center md:justify-between'>
                    <div className='flex flex-col md:w-[33%] w-full justify-center items-center text-center mt-4 md:mt-0'>
                        <img src={Logo} alt='Logo' className='rounded-2xl max-h-[80px] max-w-[80px] mb-8'/>
                        <h2>Die Halle Höchst</h2>
                        <p>vertr. u. a. d. Eckhard Hummel</p>
                        <p>Im Veilgärtchen 5</p>
                        <p>63571 Gelnhausen OT Höchst</p>
                    </div>
                    <div className='flex flex-col w-[33%] justify-center text-center items-center gap-2 text-gray-200 '>
                        <ul className='flex flex-col gap-2'>
                            <Link to='/'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Home</li>
                            </Link>
                            <Link to='/news'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>News</li>
                            </Link>
                            <Link to='/gallery'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Galerie</li>
                            </Link>
                            <Link to='/eckistipps'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Ausgehtipps</li>
                            </Link>
                        </ul>
                    </div>
                    <div className='flex flex-col w-[33%] justify-center text-center items-center gap-2 text-gray-200 '>
                        <ul className='flex flex-col gap-2'>
                            <Link to='/impress'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Impressum</li>
                            </Link>
                            <Link to='/impress'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Datenschutzerklärung</li>
                            </Link>
                            <a href='https://ref.cordes-hosting.de/hallehoechst' target='_blank' rel='noreferrer'>
                                <li className='cursor-pointer hover:text-gray-400 duration-300'>Unser Hoster</li>
                            </a>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className='flex border-t border-l border-r border-gray-600 md:gap-2 gap-8 pt-4 pb-4 text-gray-200 flex-col justify-center items-center w-full bg-gray-700 text-center'>
                <p>Frontend developed by <a href='https://steven-dautrich.de/' className='hover:text-gray-400 duration-300' rel='noreferrer' target='_blank'>Steven Dautrich</a></p>
                <p>Backend developed by Marvin Cordes</p>
                <a href='https://ref.cordes-hosting.de/hallehoechst' target='_blank' rel='noreferrer'>
                    <img src={CordesHosting} alt='Powerd by Cordes-Hosting' className='w-[360px]'/>
                </a>

            </div>
        </div>

    )
}
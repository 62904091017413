import React, {useEffect} from 'react';
import Navbar from "../navbar";
import MainHome from "../mainComponents/home/mainHome";
import Footer from "../footer";
import Partner from "../partner";

export default function Home() {

    useEffect(() => {
        document.title = 'Halle Höchst | Home'
    }, [])

    return (
        <main>
            <Navbar/>
            <MainHome/>
            <Partner/>
            <Footer/>
        </main>
    )
}
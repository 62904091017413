import React, {useEffect, useState} from 'react';
import Navbar from "../navbar";
import Footer from "../footer";
import VideoMain from "../mainComponents/videos/videoMain";

export default function Videos(){

    useEffect(() => {
        document.title = 'Halle Höchst | Videos'
    }, [])


    return(
        <main>
            <Navbar/>
            <VideoMain/>
            <Footer/>
        </main>
    )
}